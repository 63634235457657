<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-secondary ml-1">
            {{ $t('src.views.pages.auth.forgetpassword.resetYP') }}
          </h2>
        </b-link>
        <b-card-text class="mb-2">
          {{ $t('src.views.pages.auth.forgetpassword.weWS') }}
        </b-card-text>

        <!-- form -->
        <validation-observer ref="emailForm">
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              :label="$t('src.views.pages.auth.forgetpassword.email')"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="email"
                  :placeholder="$t('src.views.pages.auth.forgetpassword.johnexamplecom')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button
              type="submit"
              variant="primary"
              block
              :disabled="loading"
            >
              <b-spinner
                v-if="loading"
                small
                class="float-left"
                :label="$t('src.views.pages.auth.forgetpassword.floatedR')"
              />{{ $t('src.views.pages.auth.forgetpassword.submit') }}</b-button>
            <b-card-text class="text-center mt-2">
              <b-link
                :to="{name:'login'}"
                class="text-secondary"
              >
                <span>{{ $t('src.views.pages.auth.forgetpassword.close') }}</span>
              </b-link>
            </b-card-text>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BFormGroup, BFormInput, BSpinner, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BLink,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      userEmail: '',
      email,
      required,
    }
  },
  methods: {
    async validationForm() {
      const self = this
      this.$refs.emailForm.validate().then(async success => {
        if (success) {
          self.loading = true
          try {
            const response = await this.$http.post('/b2bpro/reset-password', { email: this.userEmail, url: `${window.location.origin}/reset-password` })
            if (response.data.code === 200) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Email Sent',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                  text: 'Reset Password Link Sent',
                },
              })
              this.$router.push('login')
            }
            self.loading = false
          } catch (error) {
            self.loading = false
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
